
  import { ICheckoutSession, IQuestionDetail, IAnswer } from "@/interfaces";
  import {
    dispatchCreateStripeCheckoutSession,
    dispatchGetCheckoutEvent,
    dispatchGetCheckoutSession,
    dispatchPrepareCheckout,
  } from "@/store/main/actions";
  import {
    readCheckoutEvent,
    readCheckoutPrep,
    readCheckoutSession,
  } from "@/store/main/getters";
  import { Component, Vue, Watch } from "vue-property-decorator";
  import EventHeader from "@/components/EventHeader.vue";
  import RegistrationQuestions from "@/components/RegistrationQuestions.vue";

  import { loadStripe } from "@stripe/stripe-js";
  import { formatMoney } from "@/utils";
  import { stripePublicKey } from "@/env";
  import { readToken } from "@/store/main/getters";
  import { dispatchCheckApiError } from "@/store/main/actions";
  import { api } from "@/api";
  import ProductCheckout from "@/components/ProductCheckout.vue";

  const stripePromise = loadStripe(stripePublicKey);

  @Component({
    components: {
      EventHeader,
      RegistrationQuestions,
      ProductCheckout,
    },
  })
  export default class Checkout extends Vue {
    stripeCheckoutSessionId: string | null = null;
    // Indicates that this component is being loaded with no existing state
    // in the vuex store (i.e. a refresh or direct navigation to this page
    // from another site). Primarily used to avoid auto stripe checkout
    // redirect if user presses back from the stripe checkout page.
    fromReload = false;
    dialog = false;
    submitted = false;
    questions: IQuestionDetail[] = [];
    answers: IAnswer[] = [];

    get checkoutEvent() {
      return readCheckoutEvent(this.$store);
    }

    get checkoutSession() {
      return readCheckoutSession(this.$store);
    }

    get checkoutPrep() {
      return readCheckoutPrep(this.$store);
    }

    formatMoney(amount: number | null) {
      return formatMoney(amount);
    }

    get loaded() {
      return this.checkoutEvent && this.checkoutSession && this.checkoutPrep;
    }

    @Watch("checkoutSession")
    public async onCheckoutSessionChanged(newCheckoutSession: ICheckoutSession | null) {
      if (newCheckoutSession) {
        if (
          newCheckoutSession.status == "ready_to_collect" &&
          newCheckoutSession.stripe_checkout_session_id
        ) {
          if (
            newCheckoutSession.stripe_checkout_session_id !==
              this.stripeCheckoutSessionId &&
            !this.fromReload
          ) {
            await this.redirectToStripeCheckout(
              newCheckoutSession.stripe_checkout_session_id,
            );
            this.submitted = false;
          } else {
            this.dialog = true;
          }
        } else if (newCheckoutSession.status === "complete") {
          this.$router.replace(`/checkout-success/${newCheckoutSession.id}`);
        }
      }
    }

    async redirectToStripeCheckout(stripeCheckoutSessionId: string) {
      const stripe = await stripePromise;
      if (stripe) {
        await stripe.redirectToCheckout({
          sessionId: stripeCheckoutSessionId,
        });
      }
    }

    public async mounted() {
      this.stripeCheckoutSessionId =
        this.checkoutSession?.stripe_checkout_session_id || null;
      this.fromReload = !this.checkoutEvent || !this.checkoutPrep;
      await dispatchGetCheckoutSession(this.$store, {
        checkoutSessionId: +this.$route.params.id,
      });
      const eventId = this.checkoutSession?.event_id;
      if (
        eventId &&
        (!this.checkoutEvent ||
          (this.checkoutEvent && eventId !== this.checkoutEvent.id))
      ) {
        await dispatchGetCheckoutEvent(this.$store, { eventId });
      }
      if (
        eventId &&
        (!this.checkoutPrep ||
          (this.checkoutPrep && eventId !== this.checkoutPrep.event_id))
      ) {
        await dispatchPrepareCheckout(this.$store, { eventId });
      }
      this.getQuestions();
    }

    async submitCheckout() {
      const paymentId = this.checkoutPrep?.payment_options.find(
        (p) => p.paymentoption_name.toLowerCase() === "stripe",
      )?.payment_id;
      const checkoutSessionId = this.checkoutSession?.id;
      if (paymentId && checkoutSessionId) {
        this.submitted = true;
        if (!this.checkoutSession?.stripe_checkout_session_id) {
          await dispatchCreateStripeCheckoutSession(this.$store, {
            checkoutSessionId,
            checkoutFillOrder: { payment_id: paymentId, answers: this.answers },
          });
        } else {
          await this.redirectToStripeCheckout(
            this.checkoutSession.stripe_checkout_session_id,
          );
          this.submitted = false;
        }
      }
    }

    async onSubmitQuestions(
      answersIn: { question: IQuestionDetail; answer: string }[],
    ) {
      this.answers = answersIn.map((q) => ({
        question_id: q.question.id.toString(),
        answer_text: Array.isArray(q.answer) ? q.answer.join(", ") : q.answer,
      }));
      this.submitCheckout();
    }

    async getQuestions() {
      const token = readToken(this.$store);
      try {
        const resp = await api.getRegistrationQuestions(token);
        this.questions = resp.data;
      } catch (error) {
        dispatchCheckApiError(this.$store, error);
      }
    }

    cancel() {
      this.$router.replace(`/event/${this.checkoutSession?.event_id}`);
    }

    async complete() {
      if (this.checkoutSession?.stripe_checkout_session_id) {
        this.submitted = true;
        await this.redirectToStripeCheckout(
          this.checkoutSession.stripe_checkout_session_id,
        );
        this.submitted = false;
      }
    }
  }
