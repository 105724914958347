import timezones from "@/timezones";

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function applyTimeZone(date: Date, utc_offset: string) {
  const diff =
    new Date("1970-01-01T00:00:00+00:00").getTime() -
    new Date(`1970-01-01T00:00:00${utc_offset}`).getTime();
  return new Date(date.getTime() + diff + date.getTimezoneOffset() * 60000);
}

export function formatDate(dateString: string, utc_offset: string | null) {
  const date = utc_offset
    ? applyTimeZone(new Date(dateString), utc_offset)
    : new Date(dateString);
  return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

export function formatTime(dateString: string, utc_offset: string | null) {
  const date = utc_offset
    ? applyTimeZone(new Date(dateString), utc_offset)
    : new Date(dateString);
  let hours = date.getHours();
  const daynight = hours >= 12 ? "pm" : "am";
  hours = hours > 12 ? hours - 12 : hours;
  let minutes = `${date.getMinutes()}`;
  minutes = minutes.length == 1 ? `0${minutes}` : minutes;

  return `${hours}:${minutes} ${daynight}`;
}

export function getTimeZone(utc_offset: string, time_zone: string) {
  const zones = timezones.filter((t) => t.value === time_zone);
  let tz = time_zone;
  if (zones.length > 0) {
    tz = zones[0].abbr;
  }
  return tz;
}

export function formatTimeRange(
  start: string,
  end: string,
  utc_offset: string,
  time_zone: string | null = null,
) {
  let timeRange = `${formatTime(start, utc_offset)} - ${formatTime(end, utc_offset)}`;
  if (time_zone) {
    const tz = getTimeZone(utc_offset, time_zone);
    timeRange += ` ${tz}`;
  }
  return timeRange;
}
