
  import { IQuestionDetail, IQuestionRule } from "@/interfaces";
  import { Component, Vue, Prop, Watch } from "vue-property-decorator";
  import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
  import { required } from "vee-validate/dist/rules";

  extend("required", { ...required, message: "{_field_} cannot be empty" });

  @Component({
    components: {
      ValidationObserver,
      ValidationProvider,
    },
  })
  export default class RegistrationQuestions extends Vue {
    $refs!: {
      observer: InstanceType<typeof ValidationObserver>;
    };

    @Prop({ default: () => [] })
    questions!: IQuestionDetail[];

    questionsAndAnswers: { question: IQuestionDetail; answer: string }[] = [];
    subQuestions: number[] = []; // a list of subquestion IDs that should be displayed
    submitting = false;

    get loaded() {
      return this.questionsAndAnswers.length > 0;
    }

    @Watch("questions")
    createAnswers() {
      this.questionsAndAnswers = this.questions.map((q) => {
        return { question: q, answer: "" };
      });
    }

    showQuestion(question: IQuestionDetail) {
      return question.is_primary || this.subQuestions.includes(question.id);
    }

    checkForSubQuestion(question: IQuestionDetail, answer: string | string[]) {
      // check if the question's answer matches one of the subquestion rules
      if (question.subquestion_rules && question.subquestion_rules.length > 0) {
        for (let rule of question.subquestion_rules) {
          if (rule.mapped_choice && rule.mapped_choice.length > 0) {
            let showQuestion = this.checkIfAnswerMatches(rule, answer);
            this.modifySubQuestionList(showQuestion, rule.subquestion_id);
          }
        }
      }
    }

    checkIfAnswerMatches(rule: IQuestionRule, answer: string | string[]) {
      // determine whether any of the answer(s) match any of the rule's mapped_choice(s)
      // If they do, we will add them to the subQuestions list and display them
      let showQuestion = false;
      for (let choice of rule.mapped_choice) {
        if (Array.isArray(answer)) {
          for (let a of answer) {
            if (a === choice) {
              showQuestion = true;
            }
          }
        } else {
          showQuestion = choice === answer;
        }
      }
      return showQuestion;
    }

    modifySubQuestionList(show: boolean, subquestion_id: number) {
      if (show && !this.subQuestions.includes(subquestion_id)) {
        // add the id to our subQuestions list
        this.subQuestions.push(subquestion_id);
      } else if (!show && this.subQuestions.includes(subquestion_id)) {
        // remove the id from our subQuestions list
        this.subQuestions = this.subQuestions.filter((q) => q !== subquestion_id);
      }
    }

    async onSubmit() {
      this.submitting = true;
      const success = await this.$refs.observer.validate();

      if (!success) {
        return;
      } else {
        this.$emit("submitted", this.questionsAndAnswers);
      }

      setTimeout(() => {
        this.submitting = false;
      }, 1000);
    }

    async mounted() {
      this.createAnswers();
    }
  }
