
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { formatDate, formatTimeRange } from "@/date-utils";
  import { IEvent } from "@/interfaces";

  @Component
  export default class EventHeader extends Vue {
    @Prop({ required: true }) public event!: IEvent | null;

    get eventDate() {
      if (this.event) {
        return formatDate(this.event.start_date, this.event.utc_offset);
      }
      return "";
    }

    get eventTime() {
      if (this.event) {
        return formatTimeRange(
          this.event.start_date,
          this.event.end_date,
          this.event.utc_offset,
        );
      }
      return "";
    }

    get isVirtual() {
      return this.event && this.event.venue === "virtual event";
    }
  }
